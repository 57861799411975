<template>
  <div>
    <TitleWrapper
      :filter-option="false"
      title="PNDG_REG"
      tooltip-title="PNDG_REG"
      :display-breadcrumb="true"
    ></TitleWrapper>
    <template v-if="isLoading"><Loader class="mt-20 mb-40" :content="true" /></template>

    <div v-else-if="!isLoading && isEmpty(registrationList)">
      <NoRecordFound />
    </div>
    <div v-else class="minHieght100 main-content bg-primary-white mt-5 p-5">
      <div class="mt-5 pb-0.5 rounded-lg border relative border-primary-grey">
        <div
          id="table-container"
          :class="[leftBlurArea, rightBlurArea]"
          class="overflow-x-auto"
          @scroll.passive="scrollDetect"
        >
          <table class="w-full table-auto whitespace-nowrap border-b border-primary-grey mb-0.5">
            <thead class="bg-bright-gray">
              <tr class="text-left">
                <th
                  v-for="head in tHeads"
                  :key="head"
                  class="font-roboto font-medium text-base text-text-color py-5 rtl:text-right rtl:pr-4 ltr:pl-4 rtl:pl-0 ltr:pr-0"
                >
                  <span
                    class="ltr:border-r rtl:border-l border-primary-grey flex items-center gap-1"
                  >
                    <span>{{ head }}</span>
                  </span>
                </th>

                <th class="table_action_th">
                  <span>Actions</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(type, index) in registrationList"
                :key="index"
                class="border-t border-primary-grey text-left"
              >
                <td
                  class="ltr:border-r rtl:border-l border-primary-grey py-5 rtl:text-right rtl:pr-4 ltr:px-4"
                >
                  <span class="font-roboto font-normal text-text-color text-sm">
                    {{ type.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
                  </span>
                </td>
                <td
                  class="ltr:border-r rtl:border-l border-primary-grey py-5 rtl:text-right rtl:pr-4 ltr:px-4"
                >
                  <span class="font-roboto font-normal text-text-color text-sm">
                    {{ type.description || GENERAL_CONSTANTS.NOT_APPLICABLE }}
                  </span>
                </td>
                <td class="border-primary-grey w-10 px-5">
                  <TableAction
                    :idx="index"
                    :current-user="type"
                    :action-list="actionList"
                    :right="50"
                    :rtl-right="-245"
                    @action="typeAction"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleWrapper from '@components/TitleWrapper.vue'
import { mapActions, mapState } from 'vuex'
import TableAction from '@components/TableAction.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import { isEmpty } from 'lodash'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import scrollMixin from '@src/mixins/scroll-mixin'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'

export default {
  components: {
    TitleWrapper,
    TableAction,
    Loader,
    NoRecordFound,
  },
  mixins: [scrollMixin],
  data() {
    return {
      isEmpty,
      GENERAL_CONSTANTS,
      tHeads: ['Title', 'Description'],
      currentSelectedType: '',
      actionList: [{ name: 'Edit' }, { name: 'Delete' }],
      registrationData: {
        title: '',
        description: '',
        is_active: true,
      },
      registrationList: [],
    }
  },
  computed: {
    ...mapState({
      showModal: (state) => state.layout.showModal,
      isLoading: (state) => state.layout.isLoading,
    }),
  },
  created() {
    this.setRightbarData()
  },
  methods: {
    ...mapActions('layout', ['setRightbarContent']),
    handleClick(eventName) {
      switch (eventName) {
        case 'showModalOpen':
          this.setShowModal(true)
          break
        case 'close':
          this.setShowModal(false)
          break
      }
    },
    typeAction(action, currentType, idx) {
      switch (action) {
        case 'Edit':
          break
        case 'Delete':
          break
      }
    },

    setRightbarData(length = 0) {
      const rightBarContent = {
        header: {
          title: 'Pending Registration',
        },
        stats: [
          {
            title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
            categories: [{ text: 'Total Pending Registrations', value: length }],
          },
        ],
      }
      this.setRightbarContent(rightBarContent)
    },
  },
}
</script>

<style lang="scss" module>
tr:nth-child(even) {
  background-color: var(--ghost-white);
}
</style>
